import profile from './profile'

const findRoute = (route, access_routes) => {
    return access_routes.find(el => el.route === route)
}

const userData = JSON.parse(localStorage.getItem('userData'))
var sortProfile = []

if (userData) {
    profile.forEach(route => {
        if (!route.children && findRoute(route.route, userData.access_routes)) {
            sortProfile.push(route)
        } else if (route.children) {
            let index = -1
            let child = JSON.parse(JSON.stringify(route.children))
            child.forEach((el) => {
                index++
                if (!findRoute(el.route, userData.access_routes)) {
                    route.children.splice(index, 1)
                    index--
                }
            })
            sortProfile.push(route)
        }
    })
}


export default [...sortProfile]