<template>
  <b-nav-item 
  :disabled="!$store.state.caller.status"
  @click="$store.commit('caller/UPDATE_CALLER_STATE', !$store.state.caller.callerState)">
    <i class="ri-phone-line" style="font-size: 23px;"></i>
  </b-nav-item>
</template>
<script>
import { BNavItem } from 'bootstrap-vue'
export default {
  components: {
    BNavItem,
  },
}
</script>
