<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
    v-if="role_id == 1"
      v-bind="linkProps"
      class="d-flex align-items-center"
      @click.once="getPath(item.title)"
    >
      <div><i style="fontSize: 23px; marginLeft: 10px;" :class="item.icon || 'ri-checkbox-blank-circle-line'"></i></div>
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-link
    v-else
      v-bind="linkProps"
      class="d-flex align-items-center"
      @click="getPath(item.title)"
    >
      <div><i style="fontSize: 23px; marginLeft: 10px;" :class="item.icon || 'ri-checkbox-blank-circle-line'"></i></div>
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  data() {
    return {
      role_id: undefined
    }
  },
  methods:{
    getPath(title){
      let path
      setTimeout(() => {
        path=this.$route.fullPath
        this.$store.commit('draggableTab/ADD_TAB',[title, path])
      }, 200)
    }
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("userData"))
    this.role_id = user.role_id
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
