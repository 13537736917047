<template>
  <draggable-modal style="z-index: 9999;" :isOpened="isOpened" @close="getClear()">
      <template #header>Водители</template>
      <div class="row">
        <div class="col-6 pl-1">
          <fieldset class="border p-1">
            <legend class="w-auto ml-2 mb-0">Водитель</legend>
            <label class="mr-1/2" for="">Логин:</label>
            <form class="d-flex" @submit.prevent="getDriverByLogin">
              <b-input size="sm" v-model="login" style="border-radius: 0.357rem 0 0 0.357rem"/>
              <button @click="getDriverByLogin" :disabled="isSearchBtnDisabled"
              class="border outline-none" style="border-radius: 0 0.357rem 0.357rem 0; border-left: none;">
                <svg viewBox="0 0 24 24" style="width: 20px; height: 20px;" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M15 15L21 21" stroke="#6e6e6e" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="#6e6e6e" stroke-width="2"></path>
                  </g>
                </svg>
              </button>
            </form>
            <hr/>
            <div v-if="driverInfo">
              <span>Логин: {{ driverInfo.login }}</span><br/>
              <span>{{ driverInfo.division }}</span><br/>
              <span>{{ driverInfo.last_name }} {{ driverInfo.first_name }} {{ driverInfo.patronymic ? driverInfo.patronymic : '' }}</span><br/>
              <span v-if="driverActiveCar">{{ driverActiveCar.car_brand }} {{ driverActiveCar.model }} {{ driverActiveCar.color }} {{ driverActiveCar.car_number }}</span><br/>
              <span class="text-primary">Статус: {{ driverInfo.status == 1 ? 'В работе' : 'Не на смене' }}</span><br/>
            </div>
          </fieldset>
        </div>
        <div class="col-6">
          <fieldset class="py-1">
            <button
            style="margin-bottom: 8px;"
            @click="isOpened = false"
            class="w-100 bg-white border border-secondary">Закрыть</button>
            <button
            style="margin-bottom: 8px;"
            :disabled="!driverInfo"
            @click="callDriver"
            class="w-100 bg-white border border-secondary">Позвонить <span class="text-secondary" v-hotkey="{ 'ctrl+f9': callDriver }">Ctrl+F9</span></button>
            <button
            :disabled="!driverInfo"
            style="margin-bottom: 8px;"
            v-b-modal.mailToDriverModal
            v-hotkey="{ 'alt+f9': openSMSModal }"
            class="w-100 bg-white border border-secondary">Отправить SMS <span class="text-secondary">Alt+F9</span></button>
            <button :disabled="!driverInfo"
            style="margin-bottom: 8px;"
            v-b-modal.driver_active_order
            v-hotkey="{ 'f2': openActiveDriverOrderModal }"
            class="w-100 bg-white border border-secondary">Открыть текущий заказ <span class="text-secondary">F2</span></button>
            <button :disabled="!driverInfo"
            style="margin-bottom: 8px;"
            v-b-modal.driver_orders
            v-hotkey="{ 'f3': openDriverOrdersModal }"
            class="w-100 bg-white border border-secondary">Открыть все заказы водителя <span class="text-secondary">F3</span></button>
            <button :disabled="!driverInfo"
            v-b-modal.driver_closed_order
            v-hotkey="{ 'f2': openClosedDriverOrderModal }"
            class="w-100 bg-white mb-1/2 border border-secondary">Открыть закрытые заказы водителя <span class="text-secondary">Ctrl+F3</span></button>
          </fieldset>
        </div>
      </div>
      <b-modal id="mailToDriverModal" title="Отправить сообщение" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12" >
                <label for="">Сообщение:</label>
                <b-form-textarea v-model="smsText" maxlength="140" placeholder="Введите текст..."/>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <b-button  class="mt-2" :disabled="sendMessageBtnDisabled" variant="primary" @click="sendMessage()">Отправить</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-modal>
      <ModalActiveOrder :driverId="driverInfo ? driverInfo.id : null"/>
      <ModalDriverOrders :driverId="driverInfo ? driverInfo.id : null"/>
      <ModalClosedOrder :driverId="driverInfo ? driverInfo.id : null"/>
    </draggable-modal>
</template>
<script>
import DraggableModal from '../DraggableModal.vue';
import MailModal from "@/views/component/Modal/ModalOrder/MailModal.vue"
import ModalActiveOrder from "@/views/component/Modal/ModalDriver/modalActiveOrder.vue"
import ModalDriverOrders from "@/views/component/Modal/ModalDriver/modalDriverOrders.vue"
import ModalClosedOrder from "@/views/component/Modal/ModalDriver/modalClosedOrder.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    DraggableModal,
    MailModal,
    ModalActiveOrder,
    ModalClosedOrder,
    ModalDriverOrders,
    ToastificationContent
  },
  data() {
    return {
      driverInfo: null,
      isOpened: false,
      isSearchBtnDisabled: false,
      login: '',
      positionId: JSON.parse(localStorage.getItem("userData")).role_id,
      sendMessageBtnDisabled: false,
      smsText: null,
    }
  },
  mounted() {
    document.body.addEventListener('keydown', (e) => {
        if ((e.which || e.keyCode) == 120 && !e.ctrlKey && !e.altKey) {
          this.getClicePerformer()
        }
      })
  },
  methods: {
    getClicePerformer() {
      this.isOpened = !this.isOpened 
    },
    callDriver(){
      if(this.$store.state.caller.status && this.driverInfo) {
        let initiatorNumber = JSON.parse(localStorage.getItem('sipConfig')).user
        this.$http.post('/connect-driver-operator', {
          number: initiatorNumber,
          phone: this.driverInfo.phone
        })
        .then(() => {
          this.$store.commit('caller/UPDATE_IS_INITIATOR_STATE', true)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
            title: 'Запрос на соединение отправлен',
            icon: 'XIcon',
            variant: 'success',
            text: "",
            },
          })
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
            title: "Ошибка",
            icon: 'XIcon',
            variant: 'danger',
            text: err,
            },
          })
        })
      }
      else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Включите SIP телефонию',
            icon: 'XIcon',
            variant: 'danger',
            text: "",
          },
        })
      }
    },
    getDriverByLogin() {
      if (this.login) {
        this.isSearchBtnDisabled = true
        this.$http.post('drivers/search-by-login', { login: this.login })
        .then(res => {
          this.driverInfo = res.data
          this.isSearchBtnDisabled = false
        })
        .catch(() => {
          this.isSearchBtnDisabled = false
        })
        .finally(() => {
          this.isSearchBtnDisabled = false
        })
      }
    },
    openActiveDriverOrderModal() {
      if (this.driverInfo) {
        this.$bvModal.show('driver_active_order')
      }
    },
    openDriverOrdersModal() {
      if (this.driverInfo) {
        this.$bvModal.show('driver_orders')
      }
    },
    openClosedDriverOrderModal() {
      if (this.driverInfo) {
        this.$bvModal.show('driver_closed_order')
      }
    },
    openSMSModal() {
      if (this.driverInfo) {
        this.$bvModal.show('mailToDriverModal')
      }
    },
    sendMessage() {
      if (this.smsText) {
        this.sendMessageBtnDisabled = true
        this.$http
        .post('sms-send', { phone: this.driverInfo.phone, message: this.smsText })
        .then(res => {
          this.sendMessageBtnDisabled = false
          this.$bvModal.hide('mailToDriverModal')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
            title: 'Успех!',
            icon: 'CheckIcon',
            variant: 'success',
            text: `Сообщение отправлено!`,
            },
          })
          this.smsText = null
        })
        .catch(err => {
          this.sendMessageBtnDisabled = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
            title: 'Некорректные данные!',
            icon: 'XIcon',
            variant: 'danger',
            text: err.response.data.errors,
            },
          })
        })
        .finally(()=>{
          this.sendMessageBtnDisabled = false
        })
      }
    },
    getClear() {
      this.isOpened = false
      this.login = ''
      this.driverInfo = null
    }
  },
  created() {
    this.$root.$on('getPerformerCard', (performer_login) => {
        this.login = performer_login
        this.getClicePerformer()
      });
  },
  computed: {
    driverActiveCar() {
      const activeCar = this.driverInfo.driver_cars.find(car => {
        return car.active == 1;
      });
      return activeCar
    }
  }
}
</script>